body {
  font-family: 'Playfair Display Variable', sans-serif;
}

h3, h4, h5, em {
  font-family: 'Homemade Apple', sans-serif;
  font-weight: normal;
}
em {
  padding: 0 0.4em;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Container-pale {
  background-color: #f8e6cb;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Playfair Display Variable', sans-serif;
  color: #282828;
  padding: 16vh 12vh;
  line-height: 6vmin;
}

.Container-yellow {
  background-color: #f8d49b;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282828;
  padding: 8vh 12vh;
  line-height: 6vmin;
}

.Container-green {
  background-color: #b9cc95;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282828;
  padding: 8vh 12vh;
  line-height: 6vmin;
}

.Container-red {
  background-color: #e984a2;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282828;
  padding: 8vh 12vh;
  line-height: 6vmin;
}

.Container-white {
  background-color: #f6f6f6;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282828;
  padding: 8vh 12vh;
  line-height: 6vmin;
}

.Hotels {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}
.Small {
  flex: 1;
  flex-basis: calc(50% - 4em);
  padding: 2em;
  background-color: #f6f6f6;
  line-height: 4vmin;
  margin-block: 0.2em;
  box-shadow: 0 2em 4em rgba(0, 0, 0, 0.1);
}

.white-button {
  display: inline-block;
  padding: 0.42em 2.2em 0.48em;
  background-color: #f9eedd;
  letter-spacing: 0.14em;
  color: #444444;
  font-size: 1.8em;
  font-weight: bolder;
  font-style: italic;
  text-align: center;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 1.1em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
  animation: pulse 3s infinite; /* Apply the pulse animation */
}

.white-button:hover {
  box-shadow: 0px 5px 20px rgba(255, 255, 255, 0.5);
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
}
